
<style>
    p{
        font-size: 2vw;
    }
</style>
<div *ngIf="category==='fruitsres'">
    
                <div *ngFor="let category of allCategories; let i = index;">
                    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productListing(category.name)"><p>{{category.name}}</p></a>
                    <!-- <h6 style="color: white!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6> -->
                </div>
           
</div>
<div *ngIf="category==='vegetablesres'">
    
    <div *ngFor="let category of allCategories1; let i = index;">
        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productListing(category.name)"><p>{{category.name}}</p></a>
        <!-- <h6 style="color: white!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6> -->
    </div>

</div>
<div *ngIf="category==='othersres'" style="    column-count: 3;">
    
    <div *ngFor="let category of allCategories2; let i = index;">
        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productListing(category.name)"><p>{{category.name}}</p></a>
        <!-- <h6 style="color: white!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6> -->
    </div>

</div>
<div *ngIf="category==='fruits'">
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5">
                <div *ngFor="let category of allCategories; let i = index;">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6>
                </div>
            </div>
            <div class="col-7">
                <ul style="column-count:2;display: block;">


                    <li *ngFor="let category of allCategories; let i = index;">

                        <img [title]=category.name [src]=category.category_icon [alt]=category.name style="height:100px;margin-bottom: 10px;cursor: pointer;width: 120px;" (click)="productListing(category.name)">
                    </li>
                </ul>
                <!-- <p class="close" *ngIf="appService.currentUrl !== 'checkout'" (click)="removeItem(i)">&#x292C;</p> -->
                <!-- <img [src]="categoryImage1" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName1)"> -->
                <!-- <img [src]="categoryImage2" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
                     <!-- <img src="assets/images/bestgifts/category2.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='vegetables'">
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            <div class="col-5">
                <div *ngFor="let category of allCategories1; let i = index;">
                    <h6 style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)">{{category.name}}</h6>
                </div>
            </div>
            <div class="col-7">
                <ul style="column-count:2;display: block;">


                    <li *ngFor="let category of allCategories1; let i = index;">

                        <img [title]=category.name [src]=category.category_icon [alt]=category.name style="height:100px;margin-bottom: 10px;cursor: pointer;width: 120px;" (click)="productListing(category.name)">
                    </li>
                </ul>
                <!-- <p class="close" *ngIf="appService.currentUrl !== 'checkout'" (click)="removeItem(i)">&#x292C;</p> -->
                <!-- <img [src]="categoryImage1" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName1)"> -->
                <!-- <img [src]="categoryImage2" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
                     <!-- <img src="assets/images/bestgifts/category2.png" style="width: 50%;height: 100%;cursor: pointer;" (click)="productListing(categoryName2)"> -->
            </div>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='others'">
   
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            
                <ul style="column-count:3">
                    <li *ngFor="let category of allCategories2; let i = index;" style="color: black!important;cursor: pointer;" class="txt" (click)="productListing(category.name)"><p style="font-size: 13px;
                        font-weight: 500;">{{category.name}}</p></li>
                </ul>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>
<div *ngIf="category==='pages'">
   
    <div class="min-cart-product-wrapper popup-content1">
        <div class="row justify-content-center" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
            
                <ul style="column-count:2">
                    <li  style="color: black!important;cursor: pointer;" class="txt">
                        <a href="#/about-us"><p style="font-size: 13px;
                            font-weight: 500;">ABOUT US</p></a>
                  </li>
                        <li  style="color: black!important;cursor: pointer;" class="txt">
                            <a href="#/contact-us"><p style="font-size: 13px;
                            font-weight: 500;">CONTACT US</p></a></li>
                            <li  style="color: black!important;cursor: pointer;" class="txt">
                                <a href="#/privacy-policy"><p style="font-size: 13px;
                                font-weight: 500;">PRIVACY POLICY</p></a></li>

<li  style="color: black!important;cursor: pointer;" class="txt">
    <a href="#/cancellation-policy"><p style="font-size: 13px;
    font-weight: 500;">CANCELLATION POLICY</p></a></li>
    <li  style="color: black!important;cursor: pointer;" class="txt">
        <a href="#/shipping-and-delivery"><p style="font-size: 13px;
        font-weight: 500;">SHIPPING AND DELIVERY</p></a></li>
        <li  style="color: black!important;cursor: pointer;" class="txt">
            <a href="#/terms-and-condition"><p style="font-size: 13px;
            font-weight: 500;">TERMS AND CONDITIONS</p></a></li>
                </ul>
            
        </div>
    <!-- <div class="text-center mr-2 mt-2">
        <div class="btn-group"> -->
        <!-- <button class="btn btn-secondary inq-btn-st"  routerLink="/cart">View Cart</button> -->
        <!-- <button *ngIf="CONFIG.title !=='BombayHardware'" [disabled]="!(appService.cartProducts.results.length > 0)" class="btn btn-primary inq-btn-st ml-1" (click)="goToCheckout()">Checkout</button> -->
    <!-- </div>
    </div> -->
    </div>
</div>