import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  @Output() loading = new EventEmitter();
  @Output() complete = new EventEmitter();
  textMsg:string = "";

  constructor() { }

  emitLoading(textMsg?):void{
    if(textMsg){
      this.textMsg = textMsg
    }
    else{
      this.textMsg = "";
    }
    this.loading.emit();
  }

  emitComplete():void{
    this.complete.emit();
  }
}
