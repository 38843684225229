import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedVariantsModule } from "src/app/Lazy-loaded-Modules/Product-Modules/product-lists/product-list/shared-variants/shared-variants.module";
import { AlertsModule } from "../alerts/alerts.module";
import { BannerComponent } from "./banner.component";

@NgModule ({
    declarations: [
        BannerComponent
    ],
    exports: [
        BannerComponent
    ],
    imports: [
        CommonModule,
        SharedVariantsModule,
        AlertsModule,
        RouterModule
    // CommonModule,
    // HomeTwoRoutingModule,
    // CarouselModule,
    // AlertsModule,
    // FeaturedCollectionsModule,
    // ProductBoxOneModule,
    // SharedVariantsModule,
    // RouterModule,
    // BestProductsModule
    ]
})

export class BannnerModule {};