<div class="container-fluid visit-store">
    <ul>
        <li>
            <h3>Visit our latest products</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()" >Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<footer class="container-fluid footer">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" (click)="windowscroll()"routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us" *ngIf = "config.title == 'ShubhCards' || config.title == 'Deera' || config.title == 'BestGifts'||config.title == 'Kubendran'">Contact Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="services" *ngIf = "config.title != 'ShubhCards' && config.title != 'Deera' && config.title != 'BestGifts'&& config.title != 'Kubendran'">Services</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="contact-us" *ngIf = "config.title != 'ShubhCards' && config.title != 'Deera' && config.title != 'BestGifts'">Contact Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/FAQs" *ngIf = "config.title != 'Deera' && config.title != 'BestGifts' ">FAQ</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/terms-and-condition">T&amp;C</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/cancellation-policy">Cancellation Policy</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/shipping-and-delivery">Shipping & Delivery</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/return-policy" *ngIf = "config.title == 'ShubhCards' || config.title == 'Deera' || config.title == 'BestGifts'">Return & Exchanges</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address" *ngIf = "config.title == 'LuckMe'">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    B S M Complex, Revolution Av,<br>
                    Victoria, Mahe
                    <br>
                    
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+248 251 3732</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="sales@supersoma.sc" target="_blank">sales@supersoma.sc</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf = "config.title == 'ShubhCards'" >
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    New #16,Subramaniam Street, Abhiramapuram,Chennai,<br>
                    Tamil Nadu 600018
                    <br>
                    
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p>
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-98400 49389</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">shubhcards@gmail.com</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf = "config.title == 'Deera'" >
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Panduranga Enterprises - Import & Trading - NO.84/1, 4th Floor, 3rd Cross, New Timber Yard Layout, Bengaluru<br>
                    Karanataka - 560026
                    <br>
                    
                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">8310065761</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">deera2021@gmail.com</a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf = "config.title == 'BestGifts'" >
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    BestGifts<br>
                    <br>
                    
                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">8072616729</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">chennaibeststudio@gmail.com                    </a>
                </p>
            </div>
            <div class="footer-col contact-section address" *ngIf = "config.title == 'Kubendran'" >
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Kubendran<br>
              
                    
                </p>
                <!-- <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91-44-42327190</a>
                </p> -->
                <p><i class="fa fa-mobile"></i>
                    <a style="color: #375673 !important;font-weight:450">&nbsp;8072616729</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450"  target="_blank">kubendran@gmail.com                    </a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer>
    <div class="footer-row-two">
        <div class="s-icons">
            <ul class="social-icons" *ngIf="config.title !== 'Deera' && config.title !== 'BestGifts'">
                <li>
                    <a href="#">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>

            <ul class="social-icons" *ngIf="config.title === 'Deera' || config.title === 'BestGifts'">
                <li style="cursor: pointer;">
                    <a *ngIf="facebookLink">
                        <i class="fa fa-facebook" (click)="windowOpen(facebookLink)"></i>
                    </a>
                </li>
                <li style="cursor: pointer;">
                    <a *ngIf="twitterLink">
                        <i class="fa fa-twitter" (click)="windowOpen(twitterLink)"></i>
                    </a>
                </li>
                <li style="cursor: pointer;">
                    <a *ngIf="linkedlnLink">
                        <i class="fa fa-linkedin" (click)="windowOpen(linkedlnLink)"></i>
                    </a>
                </li>
                <li style="cursor: pointer;">
                    <a *ngIf="instagramLink">
                        <i class="fa fa-instagram" (click)="windowOpen(instagramLink)"></i>
                    </a>
                </li>
                <li style="cursor: pointer;">
                    <a *ngIf="pinterestLink">
                        <i class="fa fa-pinterest" (click)="windowOpen(pinterestLink)"></i>
                    </a>
                </li>

            </ul>
        </div>
        <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div>
    </div>
</footer>