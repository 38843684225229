

<!-- <app-header></app-header>
<main>
    <router-outlet></router-outlet>
</main>
<app-footer></app-footer> -->
<app-header  [item]='navURL' *ngIf="(config.title == 'Tams' || config.title == 'Dosapark' || config.title == 'Murgasen' ) && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-header>
<!-- <app-header  [item]='navURL' *ngIf="config.title == 'Murgasen'"></app-header> -->
<app-header  [item]='navURL' *ngIf="(config.title == 'Haven' || config.title == 'Nesma' ) && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-header>
<app-two-header *ngIf="config.title == 'EarthyWorthy'  && currentUrl !== 'checkout' && currentUrl !== 'knowily' "></app-two-header>
<app-two-header *ngIf="config.title == 'BBold'  && currentUrl !== 'checkout' && currentUrl !== 'knowily' "></app-two-header>
<app-two-header *ngIf="(config.title == 'LuckMe' || config.title == 'ShubhCards' || config.title == 'Deera' || config.title == 'BestGifts' || config.title == 'Kubendran' || config.title == 'Aiema' || config.title == 'Beaubelle' || config.title == 'Mayan'|| config.title == 'BombayHardware') && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-two-header>
<main>
    <router-outlet></router-outlet>
    <button (click)="topFunction()" id="myBtn" title="Go to top" data-toggle="tooltip" data-placement="left"><i class="fa fa-angle-up"></i></button>
</main>
<app-footer *ngIf="(config.title == 'Tams' || config.title == 'Murgasen' || config.title == 'Mayan' )  && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-footer>
<app-footer *ngIf="(config.title == 'Haven' || config.title == 'Dosapark' || config.title == 'Nesma' ) && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-footer>
<app-two-footer *ngIf="config.title == 'EarthyWorthy' || config.title == 'BBold' || config.title == 'BombayHardware' || config.title == 'Aiema' || config.title == 'Beaubelle'  && currentUrl !== 'checkout' && currentUrl !== 'knowily'"><h1>bbold header</h1></app-two-footer>
<app-loader></app-loader>
<app-luckme-footer *ngIf="config.title == 'LuckMe' || config.title == 'ShubhCards' || config.title == 'Deera' || config.title == 'BestGifts'|| config.title == 'Kubendran'"></app-luckme-footer>
